@import "color-palette";
@import "vars";
@import "breakpoints";

.AnalyticsBanner {
    height: 90px;
    border-radius: $border-radius-12;
    background: $color-bg-blue-light;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    cursor: pointer;

    @media screen and (max-width: $breakpoint-analytics-lg) {
        flex-direction: column;
        height: auto;
        padding-bottom: 20px;
    }

    @media screen and (max-width: $breakpoint-mobile-md-lg) {
        .BlockWithSvg {
            min-width: unset;
            width: auto;
        }

        .Text {
            font-size: 18px;
            font-weight: $font-weight-semibold;
            line-height: normal;
        }

        .ButtonBlock {
            min-width: unset;
            width: auto;

            button {
                width: 100%;
                min-width: unset;
            }
        }
    }
}

.TwoLeftBlocks,
.ButtonBlock {
    display: flex;

    align-items: center;

    justify-content: space-between;
}

.TwoLeftBlocks {
    @media screen and (max-width: $breakpoint-analytics-md) {
        flex-direction: column;
        height: auto;
        padding: 0;
    }

    @media screen and (max-width: $breakpoint-analytics-lg) {
        flex-direction: column;
        height: auto;
        padding: 0;
    }
}

.ButtonBlock {
    @media screen and (max-width: $breakpoint-analytics-lg) {
        flex-direction: column;
        height: auto;
        padding-top: 10px;
    }
}

.BlockWithSvg {
    display: flex;
    align-items: center;
    min-width: 465px;
}

.Link {
    &:hover {
        text-decoration: none;
    }
}

.Pic2Cont {
    min-width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ButtonBlock {
    min-width: 300px;
}

.Text {
    font-size: 18px;
    font-weight: $font-weight-semibold;
    line-height: normal;
    margin-left: 20px;
    margin-right: 20px;

    &:hover {
        text-decoration: none;
    }
}

.Button {
    margin-left: 20px;
    border-radius: $border-radius-10;
    background: $color-bg-blue;
    display: flex;
    width: 300px;
    height: 50px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    color: $color-text-white;
    text-align: center;
    font-size: 16px;
    font-weight: $font-weight-semibold;
    line-height: normal;
    box-shadow: none;
    border: none;
    margin-right: 30px;

    &:hover {
        cursor: pointer;
        transition: box-shadow 0.3s;
        box-shadow: $shadow-deep-dark;
    }
}

.Pic1Cont {
    position: relative;
    top: 9px;
    padding-left: 20px;
}
