@import "color-palette";
@import "zIndexes";
@import "vars";
@import "breakpoints";

.MainFilter {
    min-height: 260px;
    background: $color-bg-white;
    border-radius: $border-radius-16;
    box-sizing: border-box;
    padding: 20px 30px 30px;
}

.MainPage {
    box-shadow: $shadow-mid-faint;
}

div.Right {
    display: flex;
    justify-content: flex-end;
}

.SelectFontColor {
    color: $color-text-accent;
}

.Header {
    border-bottom: 0.5px solid $color-divider-border;
    padding-bottom: 20px;
    display: flex;
    justify-content: space-between;
    position: relative;
}

.Footer {
    display: flex;
    justify-content: center;
    gap: 20px;
    padding-top: 30px;

    @media screen and (max-width: $breakpoint-main-filter-lg) {
        .CancelContainer {
            display: none;
        }

        > a {
            max-width: calc(25% - 20px);
        }
    }

    @media screen and (max-width: $breakpoint-main-filter-md) {
        gap: 40px;

        > a {
            max-width: calc(50%);
        }
    }
    @media screen and (max-width: $breakpoint-main-filter-md) {
        gap: 20px;
    }

    @media screen and (max-width: $breakpoint-main-filter-sm) {
        flex-direction: column;

        > a {
            max-width: 100%;
        }
    }
}

.ExpandedMode {
    .Footer {
        flex-direction: row-reverse;
        padding-top: 0;
        @media screen and (max-width: $breakpoint-main-filter-sm) {
            flex-direction: column;

            > a {
                max-width: 100%;
            }
        }
    }
}

.MainLine {
    padding: 30px 0 0;
    flex-wrap: wrap;

    @media screen and (max-width: $breakpoint-main-filter-lg) {
        .Col:nth-child(1),
        .Col:nth-child(2),
        .Col:nth-child(3) {
            flex-grow: 1;
            width: 100%;
            max-width: calc(50% - 20px);
        }
    }

    @media screen and (max-width: $breakpoint-tablet-sm) {
        .Col:nth-child(1),
        .Col:nth-child(2),
        .Col:nth-child(3) {
            flex-grow: 1;
            width: 100%;
            max-width: 100%;
        }
    }
}

.CancelContainer {
    width: 100%;
    display: flex;
    justify-content: flex-start;
}

.Row {
    display: flex;
    gap: 20px;
    justify-content: space-between;

    .Col {
        max-width: 346px;
        width: 100%;
        flex-grow: 1;
    }

    .SourcesCol {
        max-width: 66%;
    }
}

.ExpandedFilters {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    @media screen and (max-width: $breakpoint-tablet-md) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: $breakpoint-mobile-md-lg) {
        grid-template-columns: repeat(1, 1fr);
    }

    gap: 20px 13px;
    margin-bottom: 20px;
}

.ExpandedFilter {
    min-height: 50px;
}

.Divider {
    border-bottom: 0.5px solid $color-divider-border;
    margin: 30px 0;
}

.ModeSwitcher {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: $font-weight-semibold;
    font-size: 13px;
    line-height: 16px;

    text-align: right;

    color: $color-switcher;

    > svg {
        margin-left: 6px;
        transform: rotate(180deg);
    }

    &.Expanded {
        > svg {
            transform: rotate(0deg);
        }
    }

    @media screen and (max-width: $breakpoint-mobile-xs-md) {
        font-size: 12px;
        line-height: 14px;
    }
}

.LocationConfirmCont {
    z-index: $mainPageLocationConfirmZIndex;
    position: absolute;
    top: 35px;
}

.LocationFilterCont {
    z-index: $mainPageLocationFilterZIndex;
    position: absolute;
    top: 35px;
}

.customSelect {
    font-size: inherit;
    text-align: center;
    padding: 10px;
    color: $color-input-accent;
}

.groupSelect {
    margin-bottom: 10px;
    border-bottom: 1px solid $color-border-light;
}

.IconWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    right: 7px;
}

.CompareIcon {
    width: 24px;
    height: 24px;
    fill: $color-primary-black;

    &__badge {
        width: 22px;
        max-width: 24px;
        height: 22px;
        background-color: $color-bg-red;
        border: 2px solid $color-bg-white;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $color-text-white;
        font-size: 12px;
        font-weight: $font-weight-semibold;
        position: absolute;
        top: -5px;
        right: -8px;
    }
}